import { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import './App.css';
import { GOERLI_KYC_CONTRACT, GOERLI_KYC_ABI } from "./constants";
import wc from './witness_calculator';
import { isLeafType } from 'graphql';

const { keccak } = require('ethereumjs-util');
const circomlibjs = require('circomlibjs')
const field = require("ffjavascript");
const AIPRISE_BASE_ONBOARDING_URL = "https://onboard-sandbox.aiprise.com"
const Web3 = require('web3');
var web3 = new Web3(window.ethereum);

const snarkjs = require("snarkjs");

let wasmFile = 'https://notebooklabsdemo.s3.us-west-1.amazonaws.com/kyccompliantnew1.wasm'
let zkeyFile = 'https://notebooklabsdemo.s3.us-west-1.amazonaws.com/kyccompliantFinal1.zkey'
let verificationKey = "https://notebooklabsdemo.s3.us-west-1.amazonaws.com/verKeyKYC1.json";


const chainId = 5 // Goerli Testnet


const walletsQuery = gql`
  query getWallets {
    walletKycs(orderBy: account) {
    account
    }
  }
`;


type sigJSON = {
  r: string,
  s: string,
  v: string,
  error: string
}

export type ProofData = {
  a: bigint[],
  b: bigint[][],
  c: bigint[],
  signals: bigint[]
}




const pk = ["0x1EB47795662B78150F8208C36FA843D68A42BE9EB46FA83F6E4E0D28FCBCBDDC","0x3014CE0372ABE0C866BF4AACFF6ABDC6F84364F88A45C6252F018AB70A84C0EF"]

export default function App() {
  const [identityHash, setIdentityHash] = useState('');
  const [mnemonic, setMnemonic] = useState('');
  const [display, setDisp] = useState('');
  const [blocked, setBlocked] = useState("[]");
  const [verificationURL, setVerificationURL] = useState('');
  const [wallet, setWallet] = useState('');
  const [dispKYC, setKYC] = useState('');
  const [login, setLogin] = useState(''); //if 1 - user pressed login, if 0, user pressed create account
  const [status, setStatus] = useState('');
  var time = ''
  const { loading, error, data } = useQuery(walletsQuery);

//   React.useEffect(() => {
//     if (!loading && !error && data) {
//         console.log({ data });
//         if (window.ethereum.selectedAddress == null) {
//           web3.eth.requestAccounts().then(checkAndRedirect());
//         } else {
//           checkAndRedirect();
//         }
//     }
//  }, [loading, error, data]);

  let aiPriseWindow: any = null;

  var poseidon: any;
  var MIMC: any;
  var bn128: any;
  var F: any;

  
  //this checks if the user has a notebook account and redirects them if they do
  async function checkAndRedirect() {
    console.log("the data from the graph is", { data });

    for (let i = 0; i < data["walletKycs"].length; i++) {
      if (window.ethereum.selectedAddress.toString() == data["walletKycs"][i]["account"]) {
        var redirect_url = window.location.pathname.slice(1)
        console.log(redirect_url)
        window.location.replace(redirect_url);
      }
    }
}

  async function changeNetwork() {
    if (window.ethereum.networkVersion !== chainId) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: web3.utils.toHex(chainId) }]
        });
      } catch (err: any) {
          // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: 'Goerli Testnet',
                chainId: web3.utils.toHex(chainId),
                nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
                rpcUrls: ['https://goerli.optimism.io']
              }
            ]
          });
        }
      }
    }
  }

  async function genAndSignKeys() {
    await init_hashes();

    //check to make sure user doesn't already have an account already
    await checkAndRedirect()

    let verification_response = await fetch("https://api-sandbox.aiprise.com/api/v1/verify/get_user_verification_url", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-Api-Key": "64da53952bf74bb585705d572bcdc32e" //"64da53952bf74bb585705d572bcdc32e"
      },
      body: JSON.stringify({
        "redirect_uri": "popup_close", "template_id": "db8887de-1e9d-4858-9125-01bd5e76cee3" //"14cf75fd-1dc0-4591-ad9c-3f6e941c5d41"
      })
    });

    let verification_data = await verification_response.json()

    if (verification_data["message"] !== "success") {
      console.log("Error: Couldn't grab verification url from AIPrise")
    }

    let verification_url = verification_data["verification_url"]
    setVerificationURL(verification_url);
  }


  async function init_hashes() {
    poseidon = await circomlibjs.buildPoseidonReference();
    MIMC = await circomlibjs.buildMimcSponge();
    bn128 = await field.getCurveFromName("bn128", true);
    F = bn128.Fr;
  }

  async function kycFinishedListener(event: any) {
    console.log("kycFinishedListener")
    console.log(event.origin, event.data);
    //ensure popup wasnt previously blocked
    console.log(blocked)
    //blocked is 
    if (event.origin === AIPRISE_BASE_ONBOARDING_URL && event.data === "AiPriseVerification:Complete" && !JSON.parse(blocked).includes(verificationURL.split("verification_session_id=")[1])) {
      aiPriseWindow?.close();
      window.removeEventListener('message', kycFinishedListener);

      // TODO: implement checks that user finished the onboarding process. - i.e. ensure that popup wasnt blocked
      signKeyAfterKYC()
    }
  }

  function openAiPriseWindow() {
    console.log("openAiPriseWindow")
    let height: number = 650;
    let width: number = 400;
    window.addEventListener('message', kycFinishedListener);
    const top = window!.top!.outerHeight / 2 + window!.top!.screenY - (height / 2);
    const left = window!.top!.outerWidth / 2 + window!.top!.screenX - (width / 2);
    setKYC('')
    aiPriseWindow = window.open(
      verificationURL,
      "aiprise",
      `toolbar=no, menubar=no, width=${width}, height=${height}, top=${top}, left=${left}`
    );
    if(!aiPriseWindow || aiPriseWindow.closed || typeof aiPriseWindow.closed=='undefined') { 
      window.removeEventListener('message', kycFinishedListener)
      setVerificationURL('')
      console.log("popup was blocked")
      setKYC("Pop Up was Blocked")
      //create array of blocked hashes
      var verification_session_id = verificationURL.split("verification_session_id=")[1];
      var temp_blocked = []
      if (blocked == '') {
        temp_blocked = [verification_session_id]
        setBlocked(JSON.stringify(temp_blocked))

      } else {
        temp_blocked = JSON.parse(blocked)
        temp_blocked.add(verification_session_id)
        setBlocked(JSON.stringify(temp_blocked))
      }
      


      //POPUP BLOCKED
      //setBlocked('1')
      //store blocked as an array of blocked addresses (like a json) - need to load up then check membership. Add random url to blocked if it is blocked
    } else {
      //setBlocked('')
    }
  }


  
  async function signKeyAfterKYC() {
    console.log("signKeyAfterKYC")
    const verification_session_id = verificationURL.split("verification_session_id=")[1];
    let prefixedHash = keccak(Buffer.from(padHexToLength(identityHash, 64).slice(2), "hex"));
    //used to have _for_notebook
    let ver_res = await fetch("https://api-sandbox.aiprise.com/api/v1/verify/get_user_verification_result/" + verification_session_id, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "X-Api-Key": "64da53952bf74bb585705d572bcdc32e"
        },
    })
    let ver_res_data = await ver_res.json()
    console.log(ver_res_data);
    var timestamp = ver_res_data["created_at"].toString();
    console.log(timestamp)
    //this line isn't working
    timestamp = timestamp.substring(0, timestamp.length-8);
    console.log(timestamp)
    timestamp = timestamp + "00000"
    timestamp = "0x" + BigInt(timestamp).toString(16)
    time = timestamp

    const url = "https://notebooklabsdemo.com/sign/?msg=" + identityHash.slice(2) + "&verification_id=" + verification_session_id;
    console.log(url)
    let response = await fetch(url)

    let sigjson: sigJSON = await response.json()
    console.log(prefixedHash);
    console.log("sigjson", sigjson);

    if (sigjson.hasOwnProperty("error")) {
      setKYC("Error: " + sigjson['error']); //have an error for if popups were blocked
      return;
    }

   // registerKYC(sigjson)
    setStatus("Generating ZK Proof")
    var proofData = await getVerifyData(sigjson)
    console.log(proofData)
    //TO DO: CALL SMART CONTRACT
    const wallet_address = window.ethereum.selectedAddress;
    let call_params = { from: wallet_address, gas: 400000 };
    var eddsaCredentialManager = new web3.eth.Contract(GOERLI_KYC_ABI, GOERLI_KYC_CONTRACT, call_params);
    setStatus("Submitting Proof to Smart Contract")
    eddsaCredentialManager.methods.add_credential(proofData.a, proofData.b, proofData.c, proofData.signals).send().then(async function (receipt: boolean) {
      console.log(receipt)
      console.log("Done uploading proof!!");
      setStatus("Success!")
      //CHECK IN THE RECEIPT IF AN EVENT WAS EMITTED
      if (receipt) {
        var redirect_url = window.location.pathname.slice(1)
        window.location.replace(redirect_url);
      }
      //checkAndRedirect()
    })
  }

  async function executeCreateAccount() {
    let secret = "0x" + mnemonic;
      setIdentityHash(await singlePoseidon(secret))
      if (typeof window.ethereum !== 'undefined') {
        console.log(window.ethereum);
      }
  
      if (window.ethereum.selectedAddress == null) {
        web3.eth.requestAccounts().then(genAndSignKeys);
      } else {
        await genAndSignKeys();
      }
  }

  async function executeLogin() {
    if (window.ethereum.selectedAddress == null) {
      web3.eth.requestAccounts().then(checkAndRedirect);
    } else {
      await checkAndRedirect();
    }
    setKYC("This wallet has not yet created a Notebook Account")
  }

  async function mnemonicListener() {
  //perform the steps to create account
  if (login == "0") {
    await executeCreateAccount()

  //perform the steps to login
  } else if (login == "1") {
    await executeLogin()
  }
}

  async function identityHashListener() {
  }

  function onMnemonicChange(event: any) {
    setMnemonic(event.target.value);
  }

  useEffect(() => {
    if (mnemonic.length > 0) {
      mnemonicListener();
    }
  }, [mnemonic])

  useEffect(() => {
    if (identityHash.length > 0) {
      identityHashListener();
    }
  }, [identityHash])

  useEffect(() => {
    if (verificationURL.length > 0) {
     openAiPriseWindow()
    }
  }, [verificationURL]);



  function padHexToLength(number: string, length: number) {
    while (number.length < length + 2) {
      number = "0x0" + number.slice(2)
    }
    return number
  }

  async function poseidonHash(secret: string, nonce: string) {
    secret = padHexToLength(secret, 64)
    nonce = padHexToLength(nonce, 64)
    if (F === undefined) {
      await init_hashes();
    }
    var return_val = F.toObject(await poseidon([secret, nonce], false, 1)).toString()
    return "0x" + BigInt(return_val).toString(16)
  }


  async function singlePoseidon(secret: string) {
    secret = padHexToLength(secret, 64)
    if (F === undefined) {
      await init_hashes();
    }
    var return_val = F.toObject(await poseidon([secret], false, 1)).toString()
    return "0x" + BigInt(return_val).toString(16)
  }



  const verifyProof = async (_verificationkey: string, signals: any, proof: any) => {
    const vkey = await fetch(_verificationkey).then(function (res) {
      return res.json();
    });

    const res = await snarkjs.groth16.verify(vkey, signals, proof);
    return res;
  };


  async function getFileBuffer(filename: any) {
    let req = await fetch(filename);
    return Buffer.from(await req.arrayBuffer());
  }

  async function runProofs(auth_sig_r: any, auth_sig_s: any, wallet_address: any, init_time: any, leaf: any) {
    const input = {
      "identity_pk": [BigInt(pk[0]), BigInt(pk[1])],
      "init_time": init_time,
      "wallet_address": wallet_address,
      "auth_sig_r": auth_sig_r,
      "auth_sig_s": auth_sig_s,
      "secret": BigInt("0x" + mnemonic),
      "leaf": leaf,
    }
    console.log(input)

    var wasmBuff = await getFileBuffer(wasmFile);
    var zkeyBuff = await getFileBuffer(zkeyFile);

    let witnessCalculator = await wc(wasmBuff);

    let wtns_buff = await witnessCalculator.calculateWTNSBin(input, 0);

    const { proof: _proof, publicSignals: _signals } = await snarkjs.groth16.prove(zkeyBuff, wtns_buff);

    console.log(await verifyProof(verificationKey, _signals, _proof))

    return [_signals, _proof]
  };



  // wrapper for proveLeaf
  async function getVerifyData(signature: any) {
    var leaf = BigInt(await poseidonHash(time, identityHash))
    var auth_sig_r = signature["r"]

    var babyJub = await circomlibjs.buildBabyjub();
    var F = babyJub.F;

    auth_sig_r[0] = BigInt(auth_sig_r[0])
    auth_sig_r[1] = BigInt(auth_sig_r[1])
    console.log("inputs", auth_sig_r, BigInt(signature["s"]), leaf)
    console.log("identity hash", identityHash)
    console.log(time)

    var proofData = await runProofs(auth_sig_r, BigInt(signature["s"]), BigInt(wallet), BigInt(time), leaf)
    var returnVal: ProofData = { a: [], b: [], c: [], signals: [BigInt(0)] }
    returnVal = {
      a: [BigInt(proofData[1].pi_a[0]), BigInt(proofData[1].pi_a[1])],
      //second order of indices is flipped for solidity input
      b: [[BigInt(proofData[1].pi_b[0][1]), BigInt(proofData[1].pi_b[0][0])], [BigInt(proofData[1].pi_b[1][1]), BigInt(proofData[1].pi_b[1][0])]],
      c: [BigInt(proofData[1].pi_c[0]), BigInt(proofData[1].pi_c[1])],
      signals: proofData[0]
    }
    return returnVal

  }

  async function signData() {
    const accounts = await web3.eth.getAccounts();
    console.log(accounts)
    var signer = accounts[0];
    var milsec_deadline = Date.now() / 1000 + 100;
    console.log(milsec_deadline, "milisec");

    web3.currentProvider.sendAsync({
      method: 'net_version',
      params: [],
      jsonrpc: "2.0"
    }, function (err: any, result: any) {
      const netId = result.result;
      console.log("netId", netId);
      const msgParams = JSON.stringify({types:
        {
        EIP712Domain:[
          {name:"name",type:"string"},
          {name:"version",type:"string"},
          {name:"chainId",type:"uint256"},
          {name:"notebookContract",type:"address"}
        ],
        set:[
          {name:"sender",type:"address"},
          {name:"message",type:"string"}
        ]
      },
      //TO DO: REPLACE WITH NOTEBOOK CONTRACT
      primaryType:"set",
      domain:{name:"SetTest",version:"1",chainId:netId,notebookContract:"0x803B558Fd23967F9d37BaFe2764329327f45e89E"},
      message:{
        sender: signer,
        message: "Sign this message to prove you have access to this wallet in order to sign in to Notebook. \n This won't cost you any Ether."
      }
      })

      var from = signer;
    
      console.log('CLICKED, SENDING PERSONAL SIGN REQ', 'from', from, msgParams)
      var params = [from, msgParams]
      console.dir(params)
      setWallet(params[0])
      var method = 'eth_signTypedData_v3'
    
      web3.currentProvider.sendAsync({
        method,
        params,
        from,
      }, async function (err: any, result: any) {
        if (err) return console.dir(err)
        if (result.error) {
          alert(result.error.message)
        }
        if (result.error) return console.error('ERROR', result)
        console.log('TYPED SIGNED:' + JSON.stringify(result.result))
    

        //getting r s v from a signature
        const signature = result.result.substring(2);
        const r = "0x" + signature.substring(0, 64);
        const s = "0x" + signature.substring(64, 128);
        const v = parseInt(signature.substring(128, 130), 16);
        console.log("r:", r);
        console.log("s:", s);
        console.log("v:", v);
        //too long for field arithmetic otherwise
        setMnemonic(signature.substring(0, 55))
        console.log(signature.substring(0, 55))
      }) 
    })
  }


  async function createAccount(event: any) {
    //TO DO: CHECK IF THE USER HAS A NOTEBOOK ACCOUNT AND REDIRECT
    var redirect_url = window.location.pathname.slice(1)
    console.log(redirect_url)
    setKYC('')
    setLogin("0")
    await changeNetwork()
    if (mnemonic == '') {
      if (window.ethereum.selectedAddress == null) {
        web3.eth.requestAccounts().then(signData);
      } else {
        await signData();
      }
    } else {
      await executeCreateAccount()
    }
  }

  async function performLogin(event: any) {
    setKYC('')
    setLogin("1")
    await changeNetwork()
    if (mnemonic == '') {
      if (window.ethereum.selectedAddress == null) {
        web3.eth.requestAccounts().then(signData);
      } else {
        await signData();
      }
    } else {
      await executeLogin()
    }
  }

  return (
    <div className="App">
      <div className="notebook-page">
        <img src="/notebooklogo.svg" height="50px" className="logo" alt="Notebooklabs Logo" />
        <h1>Use Notebook to Anonymously Prove your Humanity</h1>
        <p>
          Notebook uses Zero-Knowledge Proofs to keep your personal information safe and private.
          If you've used Notebook before, simply sign in with MetaMask.
        </p>
        <p style={{ color: 'red', fontWeight: "bold" }}>{dispKYC}</p>
        
        <button onClick={createAccount}>
          Create a Notebook Account
        </button>
        <br></br>
        <br></br>
        <button onClick={performLogin}>
          Login with Notebook
        </button>
        <p> </p>
        <p style={{ color: "DeepSkyBlue", fontWeight: "bold" }}>{status}</p>
      </div>
    </div>
  );
}